import React from 'react';
import styled from 'styled-components';
import {
  Banner, BannerContent, BannerDescription, BannerContentItem, BannerTitle, WatchVideoBannerButton,
} from '../../shared/Banner'
import { PageImage } from '../../shared/PageImage';
import HowItWorksBannerIconSource from './images/howItWorksBannerIcon.svg';
import { STYLES } from '../../../style';

const StyledHowItWorksBanner = styled(Banner)``;

const BannerImage = styled(PageImage)`
`;

const HowItWorksBanner = () => (
  <StyledHowItWorksBanner color={STYLES.PAGE_HOW_IT_WORKS_BANNER_COLOR}>
    <BannerContent>
      <BannerContentItem>
        <BannerImage src={HowItWorksBannerIconSource} />
      </BannerContentItem>
      <BannerContentItem>
        <BannerTitle>How does Hand Carry work?</BannerTitle>
        <BannerDescription>
          Hand Carry requires no integration to existing work flows.
          It only requires the decision to deliver better. Simply download
          the app, register for free and start earning or saving money.
        </BannerDescription>
        <WatchVideoBannerButton />
      </BannerContentItem>
    </BannerContent>
  </StyledHowItWorksBanner>
);

export { HowItWorksBanner };