import React from 'react';
import styled from 'styled-components';

import { Layout } from '../components/shared/layout';
import { Page } from '../components/shared/Page';
import { HowItWorksBanner } from '../components/modules/HowItWorksPage';
import { DownloadCommutersApp } from '../components/modules/common/DownloadHandCarryApp';
import { HowItWorksSteps } from '../components/modules/common/HowItWorksSteps';
import { CommuterNavigation } from '../components/modules/common/CommuterNavigation/CommuterNavigation';

const StyledHowItWorksPage = styled(Layout)`
  text-align: center;
`;

const HowItWorksPage = () => (
  <StyledHowItWorksPage headerTransparent>
    <Page>
      <HowItWorksBanner />
      <HowItWorksSteps />
      <CommuterNavigation />
      <DownloadCommutersApp />
    </Page>
  </StyledHowItWorksPage>
);

export default HowItWorksPage;
